<img
  class="position-absolute top-0 end-0 background-img w-40 m-7"
  src="https://raw.githubusercontent.com/microsoft/fluentui-emoji/main/assets/Confused%20face/Color/confused_face_color.svg"
/>
<div class="panel-full d-flex justify-content-center align-items-center">
  <div class="text-center text-404">
    <img
      class="emoji"
      src="https://raw.githubusercontent.com/microsoft/fluentui-emoji/main/assets/Confused%20face/Color/confused_face_color.svg"
      height="48"
    />
    <h1 class="my-5">Sorry, we couldn't find this page.</h1>
    <p>
      This may be due to an error in entering the URL, a broken link or deletion of the page. Please check the
      URL or return to the home page to find what you're looking for.
    </p>
    <a type="button" class="btn btn-primary mt-6" href="/">
      <i class="bi bi-arrow-left-short ps-0 pe-3"></i>
      Back to home page
    </a>
  </div>
</div>

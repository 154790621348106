<div class="mx-6 my-4">
  <alto-icon-badge icon="bi-person-circle" [size]="4" class="me-4"></alto-icon-badge>
  <h1 class="title d-inline-block">{{ user ? 'Edit' : 'Create' }} a user</h1>
</div>

<form (submit)="submit()" [formGroup]="userFormGroup">
  <div class="px-6 sidebar-body">
    <div class="mb-4">
      <label class="form-label required">Firstname</label>
      <alto-input-text placeholder="John" [control]="firstnameCtrl"></alto-input-text>
    </div>
    <div class="mb-4">
      <label class="form-label required">Lastname</label>
      <alto-input-text placeholder="Doe" [control]="lastnameCtrl"></alto-input-text>
    </div>
    <div class="mb-4">
      <label class="form-label required">Email</label>
      <alto-input-text
        placeholder="john.doe@getcockpit.io"
        [control]="emailCtrl"
      ></alto-input-text>
    </div>
    <div class="mb-4">
      <label class="form-label required">Roles</label>
      <alto-input-pills placeholder="Select roles"
      [controls]="rolesCtrl"
      [options]="rolesOptions"
      ></alto-input-pills>
    </div>
    <ng-container *ngIf="differentBillingAdmin">
      <p class="fst-italic alto-warning">Adding billing admin role will remove billing admin role from {{ differentBillingAdmin.fullname }}</p>
    </ng-container>
    <ng-container *ngIf="optionsToDelete.length">
      <p class="fst-italic alto-warning">You are about to delete the following roles : {{ optionsToDelete.join(', ') }}</p>
    </ng-container>
  </div>
  <div class="footer">
    <button type="submit" class="btn btn-primary float-end" [disabled]="userFormGroup.pending || userFormGroup.invalid">
      <i class="bi bi-plus-lg"></i><span>Save</span>
    </button>
  </div>
</form>

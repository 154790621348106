<div class="mx-6 my-4">
  <alto-icon-badge icon="bi-building-gear" [size]="4" class="me-4"></alto-icon-badge>
  <h1 class="title d-inline-block">{{ coach ? 'Edit' : 'Create' }} a Coach</h1>
</div>
<form (submit)="submit()">
  <div class="px-6 sidebar-body">
    <label class="form-label required">Name</label>
    <alto-input-text [control]="nameControl" placeholder="Coach name" ></alto-input-text>
    <label class="form-label required">Picture URL</label>
    <alto-input-text [control]="pictureUrlControl" placeholder="https://example.com/picture.jpg" ></alto-input-text>
  </div>
  <div class="footer">
    <button type="submit" class="btn btn-primary float-end" [disabled]="nameControl.invalid">
      <i class="bi bi-plus-lg"></i><span>Save</span>
    </button>
  </div>
</form>

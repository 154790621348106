export const AltoRoutes = {
  home: 'home',
  companies: 'companies',
  coachs: 'coachs',
  profile: 'profile',
  notFound: '404',
  unauthorized: 'unauthorized',
  unkownError: 'error',
  user: 'user',
};

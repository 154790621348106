<img
  class="position-absolute top-0 end-0 background-img m-7"
  src="https://raw.githubusercontent.com/microsoft/fluentui-emoji/main/assets/Rocket/Color/rocket_color.svg"
/>
<div class="panel-full d-flex justify-content-center align-items-center">
  <div class="text-center text-404">
    <img class="mb-8" width="182" src="assets/img/logo.png" alt="Alto Logo" />
    <h1 class="mb-5">
      You are not authorized to access to The Office.
    </h1>
    <a class="btn btn-danger" (click)="logOut()">
      <div><i class="bi bi-box-arrow-right"></i>{{ I18ns.menu.disconnect }}</div>
    </a>
  </div>
</div>

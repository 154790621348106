<div class="logo">
  <a [routerLink]="['/', AltoRoutes.home]">
    <img width="20" src="assets/img/favicon.png" alt="Cockpit Logo" />
    <p class="ms-1">The</p>
    <p class="">Office</p>
  </a>
</div>

<div class="menu">
  <div>
    <div ngbTooltip="{{ I18ns.menu.home }}" placement="right" tooltipClass="menu-tooltip-class">
      <a
        class="module"
        [routerLink]="'/' + AltoRoutes.home"
        routerLinkActive="menu-selected"
      >
        <i class="bi bi-house-door"></i>
      </a>
    </div>
    <div ngbTooltip="{{ I18ns.menu.coachs }}" placement="right" tooltipClass="menu-tooltip-class">
      <a
        class="module"
        [routerLink]="'/' + AltoRoutes.coachs"
        routerLinkActive="menu-selected"
      >
        <i class="bi bi-bookmark-star-fill"></i>
      </a>

  </div>

  <div class="bottom-block">
    <ng-template #popContent>
      <a [routerLink]="AltoRoutes.companies + '/' + me.companyId + '/' + AltoRoutes.user + '/' + me.id">
        <div><i class="bi bi-person"></i>{{ me.fullname }}</div>
      </a>
      <a class="logout" (click)="logOut()">
        <div><i class="bi bi-box-arrow-right"></i>{{ I18ns.menu.disconnect }}</div>
      </a>
    </ng-template>
    <alto-img-badge
      [toggleTooltip]="false"
      class="img-badge-menu"
      type="button"
      placement="right"
      [ngbPopover]="popContent"
      popoverClass="menu-popover"
      placement="right-bottom"
      [user]="me"
      [size]="40"
    >
    </alto-img-badge>
  </div>
</div>

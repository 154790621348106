<div class="modal-body text-center p-5">
  <i class="alto-badge-big alto-badge-red mt-3 mb-4 bi fs-4" [class]="data && data.icon ? data.icon: 'bi-trash3'"></i>
  <h2 class="fw-bold mb-4">
    {{ data?.title ?? 'Delete' }}
  </h2>
  <p class="mb-3 alto-grey">
    {{ data?.subtitle ?? 'Are you sure you want to delete this item?' }}
  </p>
  <div class="row mt-6">
    <div class="col-6">
      <button class="btn btn-outline-secondary w-100" (click)="exit()">
        Cancel
      </button>
    </div>
    <div class="col-6">
      <button class="btn btn-danger w-100" (click)="exit(true)">
        Confirm
      </button>
    </div>
  </div>
</div>

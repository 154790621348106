<div class="pagination">
  <button [disabled]="pageControl.value === 1"
  class="btn btn-outline-secondary"
  (click)="paginate(pageControl.value - 1)">
    <i class="bi bi-arrow-left-short"></i><span>Previous</span>
  </button>

  <div *ngIf="itemsCount > itemsPerPage">
    <span class="col-form-label text-nowrap numbers">
      {{ pageControl.value + ' / ' + pageCount }}
    </span>
  </div>

  <button [disabled]="pageControl.value === pageCount"
  class="btn btn-outline-secondary"
  (click)="paginate(pageControl.value + 1)">
    <span>Next</span
    ><i class="bi bi-arrow-right-short"></i>
  </button>
</div>
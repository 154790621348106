export const environment = {
  production: true,
  trainxapiURL: 'https://trainx-api.getcockpit.io',
  auth0Domain: 'https://usealto.eu.auth0.com',
  auth0ClientId: 'gtvfSAQkXeR1UzSLfIbgVNwttvTPs16d',
  audience: 'https://api.usealto.com',
  theofficeURL: 'https://theoffice-api.getcockpit.io',
  trainxTheOfficeId: '7c2286a5-a9ab-4da4-a59f-b7971605f461',
  recordxTheOfficeId: '305fd64b-f9b7-46ae-a82f-1ca36be7088a',
  stripeCustomerURL: 'https://dashboard.stripe.com/customers/',
  auth0URL: 'https://manage.auth0.com/dashboard/eu/usealto/users/',
  sentryDsn: 'https://4c5db3174b7e575494d145068d5ecdda@o4506417447174144.ingest.sentry.io/4506558940971008',
  sentryTracesSampleRate: 1.0,
  sentryReplaysSessionSampleRate: 0,
  sentryReplaysOnErrorSampleRate: 1.0,
  sentryEnvironment: 'production',
};

<div class="header-panel">
  <div>
    <h1>
      TrainX - Coachs
    </h1>
  </div>
</div>

<!-- header coachs -->

<div class="row mb-4">
  <div class="col-4">
    <!-- searchbar -->
    <alto-input-search style="width: 400px" [searchControl]="searchTerm"></alto-input-search>
  </div>
  <div class="col text-end">
    <button class="btn btn-primary" (click)="openCoachEditForm()"><i class="bi bi-plus-lg"></i>Create a coach</button>
  </div>
</div>

<alto-placeholder-manager [status]="coachsDataStatus">
  <div class="table-panel">
    <table class="table">
      <thead>
        <tr>
          <th scope="col" class="w-20">Coach</th>
          <th scope="col" class="w-10"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let coachInfo of filteredCoachs" style="height: 53px" >
          <td class="d-flex align-items-center">
            <img [src]="coachInfo.pictureUrl" height="48" width="48" class="rounded-circle" alt="Profile Picture" />
            <span class="ms-3">
              <p class="font-size-24 mb-0">{{ coachInfo.name }}</p>
              <p class="font-size-14 text-muted mb-0">
                {{ coachInfo.id }}<i (click)="$event.stopPropagation(); copyToClipboard(coachInfo.id)" class="bi bi-clipboard cursor-pointer ms-1"></i>
              </p>
            </span>
          </td>
          <td class="text-end">
            <i (click)="$event.stopPropagation(); openCoachEditForm(coachInfo)" class="fs-4 bi bi-pen cursor-pointer me-3 p-3"></i>
          </td>
        </tr>
      </tbody>
    </table>
    <alto-pagination class="mx-4 d-block"
      [pageControl]="pageControl"
      [pageCount]="pageCount"
      [itemsCount]="coachsCount"
    ></alto-pagination>
  </div>
  <div class="nodata-placeholder d-flex justify-content-center align-items-center" noData>
    <div class="text-center">
      <img [src]="Emoji.Eyes | emoji" height="24" width="24" />
      <h2 class="mt-3">There is no coachs at the moment</h2>
      <button class="mt-4 btn btn-primary">Create the first coach</button>
    </div>
  </div>
  <div class="nodata-placeholder d-flex justify-content-center align-items-center" noResult>
    <div class="text-center">
      <img [src]="Emoji.MagnifyingGlassTiltedRight | emoji" height="24" width="24" />
      <h2 class="mt-3">Your filters doesn't match any coach</h2>
      <button class="mt-4 btn btn-primary" (click)="resetSearch()">Clean filters</button>
    </div>
  </div>
</alto-placeholder-manager>
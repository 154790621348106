<ng-template #toolTip>
  {{ getUserName(user) }}
  <br />
  {{ user?.email }}
</ng-template>
<img
  [ngbTooltip]="toggleTooltip ? toolTip : null"
  src="{{ getAvatar(user?.id) }}"
  alt="Profile Picture"
  [style]="getStyle(size)"
  [ngClass]="{ 'white-border': hasBorder }"
/>
